@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "MadeTommy";
  font-style: thin;
  font-weight: 100;
  src: local(""), url("/fonts/made_tommy/thin.otf");
}

@font-face {
  font-family: "MadeTommy";
  font-style: light;
  font-weight: 300;
  src: local(""), url("/fonts/made_tommy/light.otf");
}

@font-face {
  font-family: "MadeTommy";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("/fonts/made_tommy/regular.otf");
}

@font-face {
  font-family: "MadeTommy";
  font-style: normal;
  font-weight: 500;
  src: local(""), url("/fonts/made_tommy/medium.otf");
}

@font-face {
  font-family: "MadeTommy";
  font-style: bold;
  font-weight: 700;
  src: local(""), url("/fonts/made_tommy/bold.otf");
}

@font-face {
  font-family: "MadeTommy";
  font-style: extrabold;
  font-weight: 800;
  src: local(""), url("/fonts/made_tommy/extrabold.otf");
}

@font-face {
  font-family: "MadeTommy";
  font-style: black;
  font-weight: 900;
  src: local(""), url("/fonts/made_tommy/dark.otf");
}

.dots {
  @apply flex gap-2 !important;
}

.slick-slider {
  /* margin-left: 100px; */
  /* margin-right: 100px; */
}

.slick-slide {
  /* width: 100% !important; */
  /* width: auto !important; */
}

.slick-slide span {
  width: 100% !important;
  height: 100% !important;
}

.slick-arrow {
  /* background-color: red !important; */
  color: red !important;
}

.slick-next {
  /* right: 50px !important; */
  right: 20px !important;
}

.slick-prev {
  left: 5px !important;
  z-index: 1;
}

.slick-prev:before,
.slick-next:before {
  /* color: @apply  !important; */
  /* content: "" !important; */
  @apply text-brand-primary text-4xl !important;
}
/* 
.thumbnail-slider-wrap {
  margin-top: 15px;
  height: 85px;
}
.thumbnail-slider-wrap .slick-track .slick-slide {
  text-align: center;
}
.thumbnail-slider-wrap .slick-track .slick-slide img {
  width: 70%;
} */

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
